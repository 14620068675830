import { isNil } from '../utils/isNil'
import { AuthData } from '@panw/token-manager'
import { isNilOrEmptyString } from '@panwds/react-ui'
import { API_SERVER_FQDN_NAME, FQDNServers, FQDNTypes } from '../constants/uiConstants'
import { merge } from 'lodash'
import { uuid } from 'short-uuid'
import parser from 'json-templates'
import { ErrorItf } from '../types/types'
import doFetch, { cancelRequest } from './http-client/do-fetch'
import isEmpty from '../utils/isEmpty'
import { getAuthState } from "@sparky/framework";


export function transformFQDNsToURLsIfNecessary(fqdns: FQDNServers|any): FQDNServers {
  if (isEmpty(fqdns)) {
    return fqdns
  }
  const translated: FQDNServers|any = <FQDNServers>{};
  for (const key in fqdns) {
    let url = fqdns[key] as string
    try {
      new URL(fqdns[key])
    } catch (e) {
      if (!url.startsWith('https://')) {
        url = `https://${fqdns[key]}`
      }
    }
    translated[key] = url
  }
  return translated as FQDNServers
}

class NetworkManager {
  private static instance: NetworkManager
  private authData?: AuthData
  private serverFqdns: FQDNServers
  private API_RESOURCE: string
  private API_PATH: string
  private supportTsgId: boolean
  private isPrivateApp: boolean
  private coreapiPath: string;
  private coreapiResource: string;
  private sessionId: string | undefined

  private constructor() {
    // private constructor to enforce singleton-ness
  }

  public static getInstance(): NetworkManager {
    if (isNil(this.instance)) {
      this.instance = new NetworkManager()
      this.instance.sessionId = getAuthState()?.access_token_decoded?.jti;
    }
    return this.instance
  }

  public setServerFQDNs(servers: FQDNServers): void {
    this.serverFqdns = transformFQDNsToURLsIfNecessary(servers)
  }

  public setAPIInfo(apiInfo: {
    apiResource: string
    apiPath: string
    supportForTsgId: boolean
    isPrivateApp: boolean
  }): void {
    this.API_RESOURCE = apiInfo.apiResource
    this.API_PATH = apiInfo.apiPath
    this.supportTsgId = apiInfo.supportForTsgId
    this.isPrivateApp = apiInfo.isPrivateApp
  }

  public getServerFQDNs(): FQDNServers {
    return this.serverFqdns
  }

  public updateServersFQDNs(apiServer: string, fqdnName: FQDNTypes): void {
    if (!isNil(apiServer)) {
      const serverFQDNS = this.getServerFQDNs()
      serverFQDNS[fqdnName] = apiServer
      this.setServerFQDNs(serverFQDNS)
    }
  }

  public serverFQDNForName(fqdnName: FQDNTypes): string {
    return this.getServerFQDNs()[fqdnName] || ""
  }

  public setAuthData(authData: AuthData): void {
    this.authData = authData
  }

  public getAuthData(): AuthData {
    return this.authData!;
  }
  
  public async fetchTenantFQDN(props: {
    tenantId: string
    token: string
    api?: string
    fqdnName?: FQDNTypes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> {
    const { tenantId, token, api, fqdnName } = props
    const server = this.serverFQDNForName(fqdnName!)
    let apiPath = api ?? `${this.API_RESOURCE}/account/tenant/fqdn`
    if (!apiPath.startsWith(this.API_RESOURCE)) {
      apiPath = `${this.API_RESOURCE}${apiPath.startsWith('/') ? '' : '/'}${apiPath}`
    }
    const url = `${server}${apiPath}`
    const fetchProps = {
      method: 'GET',
      headers: this.buildHTTPHeaders({
        token,
        tenantId,
        subtenantId: "",
        requestId: 'fetchTenantFQDN',
      }),
    }
    const p = doFetch(url, fetchProps)
    try {
      const res = await p
      return res
    } catch (err: any) {
      const { response } = err

      if (!isNil(response)) {
        const error: ErrorItf = {
          errorCode: `${response.status}`,
          details: response.statusText,
          message: response.statusText,
        }

        const { errorCode } = response?.data ?? {}

        if (!isNil(errorCode)) {
          return Promise.reject(response?.data)
        }

        return Promise.reject(error)
      }
      return Promise.reject(err)
    }
  }

  public async fetchQuery(props: {
    queryBody?: string
    queryPath: string
    method?: string
    subtenant?: string
    tenant: string
    fqdnName?: FQDNTypes
    token?: string
    requestId?: string
    responseType?: string
    accept?: string
    rawAxiosResponse?: boolean
    fqdn?: string
    user?: string
    service?: string
    // TODO: find a way to use a generic type for the response
    // as now it directly returns the response from the server
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> {
    const {
      queryBody,
      queryPath,
      method = 'POST',
      subtenant = "",
      tenant,
      fqdnName,
      rawAxiosResponse,
      token = "",
      requestId = "",
      user,
      service,
    } = props
    const serverFqdnName: any = isNil(fqdnName) ? API_SERVER_FQDN_NAME : fqdnName
    
    let apiPath = queryPath.startsWith("/api") ? "" :
      parser(service === "controllercore" ? this.coreapiPath : this.API_PATH)({ tenant });
    
    let apiServer = props.fqdn;
    
    if ( service === 'ztna') {
      apiPath = '';
    }
    if (isNilOrEmptyString(apiServer)) {
      if (service === 'ztna') {
          apiServer = `${this.serverFQDNForName(serverFqdnName)}/sse/connector/v2.0/api`
      } else {
          apiServer = this.serverFQDNForName(serverFqdnName)
      }
    }
    
    if (isNil(apiServer)) {
      return Promise.reject('API server is not available yet.')
    }
    const url = `${apiServer}${apiPath}${!queryPath.startsWith('/') ? '/' + queryPath : queryPath}`
    const extraProps: any = {}
    if (method !== 'GET') {
      extraProps['body'] = queryBody
    }

    const fetchProps = merge(
      {
        method,
        cache: 'no-cache',
        headers: this.buildHTTPHeaders({
          token,
          tenantId: tenant,
          subtenantId: subtenant,
          requestId,
          user,
          service,
        }),
        rawAxiosResponse,
      },
      extraProps
    )

    if (!isNil(props.responseType)) {
      fetchProps.responseType = props.responseType
      fetchProps.acceptHeader = null // avoid send the application/json header
    } else {
      fetchProps.headers.accept = props.accept ?? 'application/json'
    }

    const p = doFetch(url, fetchProps, props.requestId)

    // p.cancel(); // can be used to cancel the request

    try {
      const res = await p
      return res
    } catch (err: any) {
      const { response } = err

      if (!isNil(response)) {
        const error: ErrorItf = {
          errorCode: `${response.status}`,
          details: response.statusText,
          message: response.statusText,
        }

        const { errorCode } = response?.data ?? {}

        if (!isNil(errorCode)) {
          // TODO: check
          return Promise.reject(response?.data)
        }

        return Promise.reject(error)
      }
      return Promise.reject(err)
    }
  }

	public cancelRequest(requestId: string, message?: string): void {
		cancelRequest(requestId, message)
	}

  /**
   * Construct the HTTP headers for each dataservice REST requests.
   * content-type/Authorization/Prisma-Client-RequestId are pretty standard
   * Customer Header "Prisma-SubTenant" should only be available for V1 API requests
   *                  this means: this is not a private APP or there is no support for TSG Id.
   * Custom Header "Prisma-Tenant" Should only be passed for V2 APIs which means that we are
   *                  in the context of the private App or we support TSG Id.
   * @param props
   * @private
   */
  private buildHTTPHeaders(props: {
    token: string
    requestId: string
    tenantId: string
    subtenantId: string
    user?: string
    service?: string
  }): Record<string, string> {
    const { token, requestId, tenantId, subtenantId, user, service } = props
    const authHeader = !isNil(token)
      ? `Bearer ${token}`
      : "Bearer " +
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczovL2V4YW1wbGUuY29tL2lzc3Vl" +
      "ciIsInVwbiI6ImRldmVsb3BlckBwYWl0ZXN0LnBhbnciLCJncm91cHMiOlsiVXNlciIsIkFkbWluIl0sImV4cCI6" +
      "MTYwOTAxMDA5NDAwMCwiaWF0IjoxNjA5MDA2NDk0MDAxLCJzdWIiOiJwYWktZGV2ZWxvcGVyIiwiYXBwcyI6eyJwc" +
      "mlzbWFfYWNjZXNzIjp7InRlbmFudHMiOlsiMTYwOTAwNjQ5NCJdLCJyb2xlIjoiQWNjb3VudCBTdXBlciBVc2VyIiw" +
      "icm9sZXMiOlsiSW5zdGFuY2UgQWRtaW4iXX19LCJqdGkiOiJZd0MzYXdzbkJVUnRqZmFtZHdtMGRRIn0";

    /**
     * API analytics
     * prisma-session-id - a unique id that represents the session.
     * prisma-client-requestId - uuid representing a single api request
     * prisma-client-widgetId - widget name representing a widget
     * prisma-client-pageId - current page of the query
     */
    const m_uuid = uuid();

    const headers: any = {
      'content-type': 'application/json',
      Authorization: authHeader,
      "prisma-session-id": this.sessionId,
      "prisma-client-pageId": window.location?.pathname,
      "prisma-client-widgetId": requestId,
      "prisma-client-requestId": m_uuid
    }
    const ztnaHeaders = {
      "content-type": "application/json",
      "Authorization": authHeader,
      "Prisma-Cached-Response": "True"
    }

    if (service === "controllercore") {
      headers["pa-request-id"] = `${isNil(requestId) ? '' : `${requestId}-`}${uuid()}`
    }

    if (!isNil(subtenantId) && this.isPrivateApp !== true && this.supportTsgId !== true) {
      headers['Prisma-SubTenant'] = subtenantId
    }
    if (this.isPrivateApp === true) {
      const prismaTenant = `${tenantId || 'panw_gcs'}${isNil(subtenantId) ? '' : `:${subtenantId}`}`;
      headers['Prisma-Tenant'] = prismaTenant;
      ztnaHeaders["Prisma-Tenant"] = prismaTenant;
    } else if (this.supportTsgId === true) {

      // eslint-disable-next-line max-len
      const prismaTenant = `${tenantId}${
        !isNil(subtenantId) && tenantId !== subtenantId ? `:${subtenantId}` : ''
      }`
      headers["Prisma-Tenant"] = prismaTenant;
      ztnaHeaders["Prisma-Tenant"] = prismaTenant;
    }
    if (!isNil(user)) {
      headers['Prisma-User'] = user
    }
    switch (service) {
      case 'ztna':
          return ztnaHeaders;
      default:
          return headers;
      }
    }
}

export default NetworkManager
